<template>
	<div class="wrap">
		<header-nav></header-nav>
		<div class="steps-wrap">
			<b-container>
				<b-row>
					<b-col>
						<el-steps :active="active" finish-status="success">
							<el-step :title="$t('orderPaymentMethodViewShoppingCart')"></el-step>
							<el-step :title="$t('orderPaymentMethodConfirmOrderInfo')"></el-step>
							<el-step :title="$t('orderPaymentMethodOrderSubmitSuccess')"></el-step>
							<el-step :title="$t('orderPaymentMethodOrderPaymentSuccess')"></el-step>
						</el-steps>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="con-wrap">
			<b-container>
				<b-row>
					<b-col>
						<div class="set-meal-wrap">
							<img src="@/../public/icon/pay/vipPaymentMethod/d11.jpg" alt="">
							<div class="tip-txt">
								<p>{{$t('orderPaymentMethodTipA')}}</p>
								<p>{{$t('orderPaymentMethodTipB')}}{{orderId}}{{$t('orderPaymentMethodTipC')}}<span>{{$t('orderPaymentMethodCurrency')}}{{price}}</span>
								</p>
							</div>
						</div>
						<div class="select-option-wrap">
							<!-- <p class="atit">{{$t('orderPaymentMethodSelect')}}</p>
							<div class="option-wrap">
								<div :class="index === PaymentMethodActive ? 'option-btn active' : 'option-btn'"
									v-for="(item,index) in paymentIconArr" :key="index"
									@click="selectPaymentMethod(item, index)">
									<img :src="item.icon" alt="">
								</div>
							</div> -->
							<div class="pay-wrap">
								<div class="pay-btn" @click="newPayment">{{$t('orderPaymentMethodImmediatePayment')}}</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<footer-nav></footer-nav>
    <form class="payment" :action="paymentParams.url" method="post" ref="form">
      <input class="a" type="text" name="charset" id="charset" :value="paymentParams.charset"/>
      <input class="a" type="text" name="data" id="data" :value="paymentParams.data"/>
      <input class="a" type="text" name="signType" id="signType" :value="paymentParams.signType"/>
      <input class="a" type="text" name="sign" id="sign" :value="paymentParams.sign"/>
      <input class="a" type="text" name="extend" id="extend" :value="paymentParams.extend"/>
    </form>
	</div>
</template>

<script>
	import headerNav from "@/components/Navbar";
	import footerNav from "@/components/Footer";
	import dump from "@/util/dump";
	export default {
		components: {
			headerNav,
			footerNav
		},
		data() {
			return {
				active: 2,
				PaymentMethodActive: 0,
				// 1支付宝 2微信 3线下
				PaymentMethod: 2,
				paymentIconArr: [{
						icon: require("@/../public/icon/pay/vipPaymentMethod/wx.jpg"),
						paymentType: 2
					},
					{
						icon: require("@/../public/icon/pay/vipPaymentMethod/zfb.jpg"),
						paymentType: 1
					},
					{
						icon: require("@/../public/icon/pay/vipPaymentMethod/xx.jpg"),
						paymentType: 3
					}
				],
				orderId: "",
				price: 0,
				token: null,
        paymentParams: {
          extend: "",
          charset: "",
          data: "",
          sign: "",
          signType: "",
          url: ""
        }
			}
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				this.orderId = localStorage.getItem("order_id");
				this.price = localStorage.getItem("price");
				this.token = localStorage.getItem("token");
        this.getMemberCardPay();
			},
			selectPaymentMethod(item, index) {
				this.PaymentMethodActive = index;
				this.PaymentMethod = item.paymentType;
			},
			// 立即支付
			payment() {
				//默认微信为6
				let paymentType=6;
				if (this.PaymentMethod === 1) {
					// 选择支付宝支付
					paymentType=7;
				}
				this.$api.aliPay({
					orderId: this.orderId,
					paymentType: paymentType,
					token: this.token
				}).then(res => {
					console.log(res);
					if (res.status === 100) {
						const params = {
							type: 1,
							link: "/pay/orderPay",
							params: {
								payType: this.PaymentMethod,
								orderId: this.orderId,
								qrCode: res.data.qrCode
							}
						}
						dump.link(params);
					}
				}).catch(err => console.error(err))
				return false;

				// this.$api.orderPay({
				// 	order_id: this.orderId,
				// 	payment_type: this.PaymentMethod,
				// 	token: this.token
				// }).then(res => {
				// 	if (res.status === 100) {
				// 		const params = {
				// 			type: 1,
				// 			link: "/pay/orderPay",
				// 			params: {
				// 				payType: this.PaymentMethod,
				// 				orderId: this.orderId
				// 			}
				// 		}
				// 		// 微信支付
				// 		if (this.PaymentMethod == 2) {
				// 			params.params.qrCode = res.data;
				// 		}

				// 		dump.link(params);
				// 	}
				// }).catch(err => console.error(err))
			},
      
      getMemberCardPay(){
        this.$api.aliPay({
          orderId: this.orderId,
          token: this.token,
          // 7 杉德支付
          paymentType: "7"
        }).then(res => {
          if(res.status == 100){
            this.paymentParams.extend = res.data.extend;
            this.paymentParams.charset = res.data.charset;
            this.paymentParams.data = res.data.data;
            this.paymentParams.sign = res.data.sign;
            this.paymentParams.signType = res.data.signType;
            this.paymentParams.url = res.data.url;
          }
        }).catch(err => console.error(err))
      },
      // 调用杉德支付 
      newPayment(){
        this.$refs.form.submit();
      }
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/view/pay/orderPaymentMethod/orderPaymentMethod";
</style>
